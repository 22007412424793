/* You can add global styles to this file, and also import other style files */
.center {
  text-align: center;
}
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.clickable:hover {
  cursor: pointer;
}

/* This style needs to be global in order to customize the background of the 1x icon.
   Component specific styles do not work for cutomizing 1x icons.
 */
.icon-bg-gray-03 .background {
  fill: #E4E3E3;;
}

.icon-bg-gray-03 .background rect {
  fill: #E4E3E3 !important;
}

/* for sticky footer */
body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  min-width: 320px;
  overscroll-behavior-y: none;
}

html,
body {
  height: 100%;
}
